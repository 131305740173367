import { createAction, handleActions } from 'redux-actions';
import { filterMetrics } from './capitalPlanning';

export const types = {
  CSV_REQUEST: 'app/csv/CSV_REQUEST',
  CSV_RESPONSE: 'app/csv/CSV_RESPONSE',
};

export const actions = {
  csvRequest: createAction(types.CSV_REQUEST),
  csvResponse: createAction(types.CSV_RESPONSE),
};

const defaultState = {
  isRefreshing: false,
  csvData: null,
};

// - - - FETCH CSV  - - -
// - - - FETCH CSV  - - -
// - - - FETCH CSV  - - -

export const fetchCSV = (namespace, method, params) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.csvRequest());
  try {
    if (api[namespace] && api[namespace][method]) {
      const { data } = await api[namespace][method](params);
      return dispatch(
        actions.csvResponse({
          csvData: data,
          csvTitle: namespace + '.' + method.replace('CSV', ''),
        })
      );
    }
    return;
  } catch (err) {
    return dispatch(actions.csvResponse(err));
  }
};

// - - - FETCH ASSET LIST CSV  - - -
// - - - FETCH ASSET LIST CSV  - - -
// - - - FETCH ASSET LIST CSV  - - -

export const fetchAssetListCSV = (metricFilters, useObserved) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.csvRequest());
  try {
    const _metricFilters = filterMetrics(metricFilters, _getState().capitalPlanning);
    const { data } = await api.capitalPlanning.assetListCsv(
      _metricFilters,
      useObserved,

      Object.keys(_getState().capitalPlanning.assetBudgetReplacementYears).map(
        item => {
          return {
            equipmentId: item,
            value: String(
              _getState().capitalPlanning.assetBudgetReplacementYears[item]
            ),
          };
        }
      )
    );
    return dispatch(
      actions.csvResponse({
        csvData: data,
        csvTitle: 'capitalPlanning.assetList',
      })
    );
  } catch (err) {
    return dispatch(actions.csvResponse(err));
  }
};

// - - - FETCH METRIC SCORING CSV  - - -
// - - - FETCH METRIC SCORING CSV  - - -
// - - - FETCH METRIC SCORING CSV  - - -

export const fetchMetricScoringCSV = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.csvRequest());
  try {
    const { data } = await api.capitalPlanning.metricScoringCsv();
    return dispatch(
      actions.csvResponse({
        csvData: data,
        csvTitle: 'capitalPlanning.metricScoring',
      })
    );
  } catch (err) {
    return dispatch(actions.csvResponse(err));
  }
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -

export default handleActions(
  {
    [actions.csvRequest]: {
      next: state => ({
        ...state,
        csvData: null,
        csvTitle: null,
        isRefreshing: true,
      }),
    },

    [actions.csvResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        csvData: payload.csvData,
        csvTitle: payload.csvTitle,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },
  },
  defaultState
);
